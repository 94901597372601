<template>
  <!-- 我的课程 -->
  <div id="app" style="background: #f4f4f4; position: relative">
    <!--      左右悬浮的icon-->
    <img class="lesson-icon1" src="@/assets/img/lesson-center-icon1.png" />
    <img class="lesson-icon2" src="@/assets/img/lesson-center-icon2.png" />
    <img class="lesson-icon3" src="@/assets/img/lesson-center-icon3.png" />
    <img class="lesson-icon4" src="@/assets/img/lesson-center-icon4.png" />
    <div class="lesson-ban">
      <img src="@/assets/img/lesson-ban.png" />
      <div class="ban-words">
        <h2>{{ this.$route.query.courseName }}</h2>
        <!-- <p>北美SDE算法速成班北美SDE算法速成班北美SDE算法速成班</p>
        <p>北美SDE算法速成班北美SDE算法速成班北美SDE算法速成班</p>-->
      </div>
    </div>
    <div class="lesson-con cson-container">
      <!-- <div class="tabs">
        <ul>
          <li
            v-for="(item,index) in  tabs"
            :key="index"
            :class="index==TabActive?'active':''"
            @click="tabChange(index)"
          >{{item.name}}</li>
        </ul>
      </div>-->
      <!--      左侧悬浮-->
      <div
        v-if="TabActive == 0"
        class="left-nav"
        :style="{ top: Top + 'px', position: position }"
      >
        <h2>任务规划</h2>
        <ul>
          <li
            v-for="(nav, jj) in lessons"
            :key="jj"
            :class="jj == TabCur ? 'active' : ''"
            @click="tabSelect(jj)"
          >
            <a>{{ nav.title | filterTitle }}</a>
            <!-- :href="'#week' + jj" -->
          </li>
        </ul>
      </div>
      <div class="tabs-con">
        <div v-if="TabActive == 0" class="content">
          <div
            v-for="(item, index) in lessons"
            :ref="'week' + index"
            :id="'week' + index"
            :key="index"
            class="big-list"
          >
            <div class="line">
              <img class="icon" src="@/assets/img/broadcast-icon.png" />
              {{ item.title }}
              <p v-if="checkEndTime(index) && checkStartTime(index)">
                学习周期：{{ item.date }}
                <img
                  :class="item.isopen ? 'opend' : ''"
                  class="icon"
                  src="@/assets/img/change-icon.png"
                  @click="change(index)"
                />
              </p>
              <p v-else-if="checkStartTime(index)">学习结束</p>
            </div>
            <div :class="item.isopen ? 'opend' : ''" class="card-con">
              <div v-for="(child, ii) in item.children" :key="ii" class="card">
                <div class="head">{{ child.title }}</div>
                <div class="sbody">
                  <p>
                    <!--                                        学习建议：-->
                    <!--                                        <br />-->
                    {{ child.sug ? child.sug : "--" }}
                    <!--                                        <br />-->
                  </p>
                  <!--                                    <h2 :class="child.state.value == 0 ? 'gray' : ''">-->
                  <!--                                        {{ child.state.name }}-->
                  <!--                                    </h2>-->
                </div>
                <div class="sbody">
                  <button
                    v-if="child.state.value == 1"
                    @click="goon(child, index)"
                  >
                    继续学习
                  </button>
                  <button
                    v-if="child.state.value == 0"
                    @click="goon(child, index)"
                  >
                    开始学习
                  </button>
                  <button
                    v-if="child.state.value == 2"
                    @click="goon(child, index)"
                  >
                    已完成
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActive == 1" class="content">1</div>
        <div v-if="TabActive == 2" class="content">2</div>
        <div v-if="TabActive == 3" class="content">3</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPlanning } from "../../../service/index";

export default {
  data() {
    return {
      position: "fixed",
      Top: 400,
      CALC_TOP: 0,
      calctop: 0,
      pageData: [],
      TabActive: 0,

      tabs: [
        {
          name: "课程规划",
        },
        {
          name: "课程评价",
        },
        {
          name: "课程小测",
        },
        {
          name: "我的打卡",
        },
      ],
      lessons: [
        {
          title: "Week 1 : 位运算，数学题",
          date: "12/3-12/10",
          isopen: true,
          children: [
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "未完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
          ],
        },
        {
          title: "Week 2 : 位运算，数学题",
          date: "12/3-12/10",
          isopen: false,
          children: [
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "未完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
          ],
        },
        {
          title: "Week 3 : 位运算，数学题",
          date: "12/3-12/10",
          isopen: false,
          children: [
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
          ],
        },
        {
          title: "Week 4 : 位运算，数学题",
          date: "12/3-12/10",
          isopen: false,
          children: [
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
          ],
        },
        {
          title: "Week 5 : 位运算，数学题",
          date: "12/3-12/10",
          isopen: false,
          children: [
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
            {
              title: "本周任务规划",
              sug: "本周学习建议从基础开始看,习建议本周学习建议本周学习",
              state: "已完成",
            },
          ],
        },
      ],
      TabCur: 0,
    };
  },
  filters: {
    filterTitle(val) {
      if (val.includes(":")) {
        return val.split(":")[0];
      } else if (val.includes("：")) {
        return val.split("：")[0];
      } else {
        return val;
      }
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.path = this.$route.path;
    getPlanning(this.$route.query.userCourseId).then((resp) => {
      // console.log('resp', resp)
      this.pageData = resp.result;
      if (this.pageData.length) {
        this.handleFilterLesson(this.pageData);
      } else {
        this.$notify({
          title: "警告",
          message: "数据获取失败",
          type: "warning",
          duration: 2000,
        });
      }
      console.log("pageData", this.pageData);
    });

    this.$nextTick(() => {
      setTimeout(() => {
        let maiH = document.querySelector(".lesson-con").offsetTop;
        // let NavH = document.querySelector('.house-header').offsetHeight;

        this.Top = maiH + 53 + 185;
        this.calctop = JSON.parse(JSON.stringify(this.Top));
      }, 300);

      // window.addEventListener("scroll", () => {
      //     let {
      //         scrollTop
      //     } = document.scrollingElement;

      //     if(this.Top <= 110) {
      //         this.CALC_TOP = scrollTop;
      //         return false
      //     };

      //     if(scrollTop <= this.CALC_TOP && this.CALC_TOP) {
      //         this.Top = this.CALC_TOP - scrollTop + 110;
      //     }

      //     this.Top = this.calctop - scrollTop;
      // });
    });
  },
  methods: {
    tabChange(index) {
      this.TabActive = index;
    },
    tabSelect(index) {
      // for(let i in this.lessons) {
      //     this.lessons[i]['isopen'] = false;
      // }
      // this.lessons[index]['isopen'] = true;
      this.TabCur = index;
      this.$nextTick(() => {
        setTimeout(() => {
          let targetbox = document.getElementById("week" + index);
          window.scrollTo(0, targetbox.offsetTop);
        });
      });
      // document.getElementById('week'+index).scrollIntoView();
    },
    change(index) {
      this.lessons[index].isopen = !this.lessons[index].isopen;
    },
    checkStartTime(index) {
      if (this.pageData[index].intervalTime == 0) {
        return false;
      }
      return true;
    },
    checkEndTime(index) {
      var endTime = new Date(this.pageData[index].endTime);

      var now = new Date();
      console.log(now);
      endTime.setTime(endTime.getTime() + 16 * 60 * 60 * 1000);
      console.log(endTime);
      if (endTime > now) {
        return true;
      }
      return false;
    },
    goon(child, index) {
      console.log;
      console.log(this.pageData[index]);
      var startTime = new Date(this.pageData[index].startTime);
      startTime.setTime(startTime.getTime() - 8 * 60 * 60 * 1000);
      var now = new Date();
      if (startTime > now) {
        this.$message.closeAll();
        this.$message.warning("还未到开始时间");
        return;
      }

      this.$router.push({
        path: "/course/video",
        query: {
          courseId: this.$route.query.courseId,
          superType: this.$route.query.superType,
          userCourseId: this.$route.query.userCourseId,
          modelId: child.id,
        },
      });
    },

    /**
     * @description 数据过滤处理
     */
    handleFilterLesson(list) {
      let lessons = [];

      for (let i = 0; i < list.length; i++) {
        lessons.push({
          title: list[i]["name"],
          date: list[i]["startTime"] + "-" + list[i]["endTime"],
          // isopen: i === 0 ? true : false,//
          isopen: this.checkEndTime(i),
          children: [],
        });

        let children = list[i]["modelVOS"];

        if (children && !!children.length) {
          for (let j = 0; j < children.length; j++) {
            lessons[i]["children"].push({
              title: children[j]["name"],
              sug: children[j]["brief"],
              state: children[j]["sectionStudyStatus"],
              id: children[j]["id"],
            });
          }
        }
      }

      this.lessons = lessons;
      console.log("lessons", this.lessons);
    },
  },
};
</script>

<style scoped>
</style>
